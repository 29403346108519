<template>    
        <s-crud
        add
        edit
        remove
        title="Mantenimiento de Artículos"  
        @save="save($event)"
        @rowSelected="rowSelected($event)" 
        :config="config"
        :filter="filter"
        search-input
        ref="crud"
        >  
        <template scope="props">
            <v-col>
                <v-row justify="center">
                    <v-col class="s-col-form" sm="6" md="6" lg="6">
                        <s-text v-model="props.item.ArtDescription" label="Artículos" >
                        </s-text>                     
                    </v-col>
                    <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
                        <s-text v-model="props.item.ArtUnitMeasure" label="Unidad de Medida" > </s-text> 
                    </v-col>                    
                    <v-col class="s-col-form" sm="3" md="3" lg="3">
                        <s-select-definition v-model="props.item.ArtStatus" :def="1152" label="Estado">                            
                        </s-select-definition>
                    </v-col>                    
                </v-row>
            </v-col>
        </template>
        <template v-slot:ArtStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.ArtStatus == 1 ? 'success' : 'error'">
            {{ row.ArtStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template> 
        </s-crud>
    
</template>

<script >
import _sArticle  from "@/services/Logistics/ArticlesService";

export default {
    name: "LogisticsArticles",
    components: {},
    props: {
        AreasID: { type: Number , default: 0 },
         addNoFuncion: { type: Boolean , default: false },
    },
    data() {
        return {
            dialogEdit: false,
           };
    },
    computed: {
        config() {
            return{
                model: {                         
                    ArtID:"ID",
                    ArtStatus: "status",
                   },
                service: _sArticle,
                headers: this.$fun.getSecurity().IsLevelAdmin
                ? [
                    { text: "ID" , value: "ArtID" ,  sortable: false },
                    { text: "Descripción" , value: "ArtDescription" , sortable: false },
                    { text: "Unidad M." , value: "ArtUnitMeasure" , sortable: false },
                    { text: "Estado" , value: "ArtStatus" , sortable: false },
                ]
                : [
                    { text: "ID" , value: "ArtID" ,  sortable: false },
                    { text: "Descripción" , value: "ArtDescription" , sortable: false },
                    { text: "Unidad M." , value: "ArtUnitMeasure" , sortable: false },
                    { text: "Estado" , value: "ArtStatus" , sortable: false },
                ],
            };
        },
        isAdmin(){
            this.$fun.getSecurity().IsLevelAdmin;
        },
    },
    methods: {
        Inicialize() {            
            this.filter = {AreID: this.AreasID};
        },
        edit(item){
            this.dialogEdit = true;
        },
        rowSelected(rows) {
            if (rows.length > 0) {
                this.$emit("rowSelected", rows);
            }
        },
        save(item) {
            //item.ArtDescription = this.ArtDescription;
            item.SecStatus = 1;
             if (item.ArtDescription == 0) {
                this.$fun.alert("Digite un articulo", "warning");
                return;
            }
            if (this.validationForm(item));
            console.log(item);
            item.save();            
        },
        validationForm(item){
            return true;
        },        
    }, 
    watch: {
        AreasID() {
            this.Inicialize();
        },
    },
    created() {
        this.filter = {AreID: this.AreasID};
    },
    
};
</script>
